<template>
  <v-carousel
    cycle
    :interval="interval"
    :height="assistHeight"
    :show-arrows="showArrows"
    hide-delimiter-background
  >
    <v-carousel-item
      v-for="(item, index) in items"
      :key="index"
    >
      <land-img
        height="100%"
        :src="item.cover"
        flat
        max-width="100%"
        tile
      >
        <div class="co-flex-col co-justify-between co-h-full">
          <div class="co-flex-col co-flex-grow co-w-full" />
          <div
            v-if="item.coverName || item.coverAuthor"
            class="co-flex-row co-justify-end co-w-full co-h32 px-10"
          >
            <span class="co-text-2xs white--text">
              {{ item.coverName }}
              <span
                v-if="item.coverAuthor"
                class="px-1"
              >by</span>
              {{ item.coverAuthor }}
            </span>
          </div>
        </div>
      </land-img>
    </v-carousel-item>
  </v-carousel>
</template>

<script>

  import api from '@/api/co.api'
  import web from '@/api/web/co.web'

  const DEF_HT = 800

  export default {
    data () {
      return {
        showArrows: false,
        interval: 5000,
        params: null,
        items: [],
        points: {
          xs: 460,
          sm: 640,
          md: DEF_HT,
        },
      }
    },
    computed: {
      assistHeight () {
        const name = this.$vuetify.breakpoint.name
        const ht = this.points[name] || DEF_HT
        const ah = Math.floor(ht * 0.7)
        return ah
      },
    },
    created () {
      this.getData()
    },
    methods: {
      getData () {
        this.items = []
        const executed = function (res) {
          if (res.status) {
          }
        }

        this.params = web.banner.getParams({
          type: web.comm.BannerTypes.LOGIN_ASSIST,
          state: web.comm.States.PUBLISH,
          caches: this.items,
          executed
        })
        api.httpx.getItems(this.params)
      }
    }
  }
</script>
